<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12" md="6" lg="4">
          <span class="mb-1 small-text">Key</span>
        </b-col>
        <b-col>
          <span class="mb-1 small-text">Value</span>
        </b-col>
      </b-row>
      <b-row v-for="(keyValue, index) in value" :key="index" class="mb-1">
        <b-col cols="12" md="4">
          <b-form-input
            v-model.trim="keyValue.key"
            size="sm"
            debounce="500"
            maxLength="100"
            @change="keyValue.key = _alphanumericWithUnderscore($event.trim())"
            pattern="[a-zA-Z0-9_]+"
            title="alphanumeric or underscore only"
            required
            :readonly="isKeyReadonly(keyValue.key)"
          />
        </b-col>
        <b-col>
          <b-form-input
            v-model.trim="keyValue.value"
            size="sm"
            debounce="500"
            @change="keyValue.value = $event.trim()"
          />
        </b-col>
        <b-col cols="1">
          <b-icon-dash-square
            class="clickable"
            variant="danger"
            @click="removeKeyValue(keyValue)"
            :class="{ 'text-muted': isKeyReadonly(keyValue.key), 'clickable': !isKeyReadonly(keyValue.key) }"
            :style="{ cursor: isKeyReadonly(keyValue.key) ? 'not-allowed' : 'pointer' }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span
            class="clickable text-primary"
            style="font-size: 0.85em"
            @click="value.push({ key: '', value: '' })"
            ><b-icon-plus /> Add Key Value</span
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {};
  },
  methods: {
    // Method to check if the key should be readonly or non-removable
    isKeyReadonly(key) {
      return ['Default', 'failed', 'confirm', 'success', 'error_msg', 'msg_login_link_name', 'msg_require_authentication', 'msg_after_login_intent_link'].includes(key); // Add the non-removable keys here
    },

    removeKeyValue(keyValue) {
      if (this.isKeyReadonly(keyValue.key)) {
        // Optionally, show a message or handle the non-removal case
        this.$bvToast.toast(`Cannot remove the "${keyValue.key}" key.`, {
          title: 'Action Denied',
          variant: 'warning',
          solid: true,
        });
        return;
      }
      this.value.splice(this.value.indexOf(keyValue), 1);
    },
  },
  mounted() {},
  watch: {
    value: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
