<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <div class="d-inline-block mr-3">
          <span class="page-header">Train Chat Bot</span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <bot-selector @botChanged="changeBot" />
      </b-col>
      <b-col>
        <b-button variant="primary" size="sm" class="ml-2" @click="trainBot">
          <b-icon-tools style="font-size: 0.8em" />
          Train
        </b-button>
      </b-col>
    </b-row>
    <section v-if="status.length > 0">
      <b-row class="mt-5">
        <b-col>
          <h6>Training Log</h6>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="border p-2">
            <p>{{ status }}</p>
          </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import BotSelector from '@/components/UI/BotSelector';

export default {
  mixins: [httpclient],
  components: { BotSelector },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.changeBot(botId);
  },
  data() {
    return {
      bots: [],
      botId: -1,
      status: '',
    };
  },
  methods: {
    changeBot(botId) {
      if (botId) this.botId = botId;
    },
    trainBot() {
      const vm = this;
      const confirmMessage = 'Proceed to train the bot?';

      vm.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.httpclient.post('/trainbots/' + vm.botId).then((resp) => {
              vm.status = resp.data.message;
            });
          }
        });
    },
  },
};
</script>