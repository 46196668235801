<template>
  <div>
    <b-row>
      <b-col cols="10">
        <div class="d-inline-block mr-3">
          <span class="page-header">Pre Chat Settings</span>
        </div>
        <pre-chat-activity-log class="align-top pt-3" :botId="formItem.botId" />
      </b-col>
      <b-col cols="3" class="mt-sm-3">
        <bot-selector @botChanged="changeBot" />
      </b-col>
    </b-row>

    <b-row>
      <b-col class="mt-5">
        <b-form-checkbox v-model="formItem.enablePreChat"
          >Enable Pre Chat?
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row v-if="formItem.enablePreChat" class="mt-3 ml-3">
      <b-col cols="12" class="">
        <pre-chat-preview
          :form-item="formItem"
          :pre-chat-banner="preChatBanner"
        />
      </b-col>
      <b-col cols="12">
        <b-form ref="main-form">
          <b-row class="my-2">
            <b-col cols="12" lg="4">
              <label>Greeting Message</label>
            </b-col>
            <b-col cols="12" lg="8">
              <b-input
                v-focus
                class="form-control-sm"
                v-model="formItem.greetingMessage"
                trim
                required
              ></b-input>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" lg="4">
              <label>Username Placeholder</label>
            </b-col>
            <b-col cols="12" lg="8">
              <b-input
                class="form-control-sm"
                v-model="formItem.usernamePlaceholder"
                trim
                required
              ></b-input>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" lg="4">
              <label>Invalid Username Alert Message</label>
            </b-col>
            <b-col cols="12" lg="8">
              <b-input
                class="form-control-sm"
                v-model="formItem.invalidUsernameAlertMessage"
                trim
                required
              ></b-input>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" lg="4">
              <label>Submit Button Text</label>
            </b-col>
            <b-col cols="12" lg="8">
              <b-input
                class="form-control-sm"
                v-model="formItem.submitButtonText"
                trim
                required
              ></b-input>
            </b-col>
          </b-row>

          <b-row class="mb-2 mt-5">
            <b-col cols="12">
              <b-form-checkbox v-model="formItem.enablePreChatBanner"
                >Use custom banner for Pre Chat?
              </b-form-checkbox>
            </b-col>
            <b-col v-if="formItem.enablePreChatBanner" cols="12">
              <small class="text-muted ml-3 mt-1"
                >Banner size: 480 x 160 px</small
              >
              <image-handler
                class="ml-3"
                :customPreviewSettings="{
                  rounded: true,
                  width: 480,
                  height: 160,
                }"
                :image.sync="preChatBanner"
                :maxDimensions="{ width: 960, height: 320 }"
              />
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12">
        <b-button class="float-right" variant="primary" @click="promptConfirm"
          >Submit
        </b-button>
      </b-col>
    </b-row>
    <div v-if="isBusy" class="spinner-busy">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import ImageHandler from '@/components/ImageHandler';
import BotSelector from '@/components/UI/BotSelector';
import PreChatPreview from '@/components/Client/PreChatPreview';
import PreChatActivityLog from '@/components/Bots/PreChatActivityLog.vue';

export default {
  /*global _*/
  mixins: [httpclient],
  components: {
    ImageHandler,
    BotSelector,
    PreChatPreview,
    PreChatActivityLog,
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.changeBot(botId);
  },
  data() {
    return {
      bots: [],
      formItem: {
        botId: -1,
        enablePreChat: false,
        greetingMessage: '',
        usernamePlaceholder: '',
        invalidUsernameAlertMessage: '',
        submitButtonText: '',
        enablePreChatBanner: '',
        prechatBannerUrl: '',
        bannerFileName: '',
        bannerFileBytes: '',
      },
      preChatBanner: {
        fileName: null,
        fileBytes: null,
        fileUrl: null,
        originalFileUrl: null,
      },
    };
  },
  methods: {
    changeBot(botId) {
      if (botId) this.formItem.botId = botId;
      const vm = this;

      vm.httpclient
        .get('/api/prechat/' + this.formItem.botId)
        .then(function (resp) {
          if (resp.data) {
            vm.formItem = _.cloneDeep(resp.data);
            setTimeout(function () {
              vm.preChatBanner = _.cloneDeep({
                fileName: vm.formItem.bannerFileName,
                fileBytes: vm.formItem.bannerFileBytes,
                fileUrl: null,
                originalFileUrl: vm.formItem.preChatBannerUrl,
              });
            }, 100);
          }
        });
    },
    promptConfirm() {
      const vm = this;

      const mainForm = vm.$refs['main-form'];

      if (mainForm && !mainForm.reportValidity()) {
        return;
      }

      const confirmMessage = 'Submit the Pre Chat settings?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.addOrEdit();
          }
        });
    },
    addOrEdit() {
      const vm = this;

      vm.formItem.bannerFileName = vm.preChatBanner.fileName;
      vm.formItem.bannerFileBytes = vm.preChatBanner.fileBytes;
      vm.formItem.preChatBannerUrl = vm.preChatBanner.originalFileUrl;

      vm.httpclient.post('/api/prechat', vm.formItem).then(function () {
        vm.$bvModal
          .msgBoxOk('Pre Chat settings had been updated successfully', {
            okVariant: 'success',
          })
          .then(function () {
            vm.changeBot();
          });
      });
    },
  },
};
</script>
