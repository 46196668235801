<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-inline-block mr-3">
          <span class="page-header">Client UI Settings</span>
        </div>
        <client-ui-config-activity-log class="align-top pt-3" :botId="botId" />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" class="mt-sm-3">
        <bot-selector @botChanged="changeBot" style="max-width: 200px" />
        <b-button
          v-b-toggle.collapse-1
          class="float-right d-none d-md-block"
          size="sm"
          variant="outline-primary"
          >Preview
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <b-row class="my-3 d-none d-md-block">
      <b-col>
        <b-collapse id="collapse-1" class="mt-2" visible>
          <!--          <span class="d-inline-block mr-3 align-top">Screen Size: </span>-->
          <!--          <b-form-select-->
          <!--            class="mb-3"-->
          <!--            v-model="previewSize"-->
          <!--            size="sm"-->
          <!--            style="max-width: 180px"-->
          <!--          >-->
          <!--            <b-form-select-option :value="'wide'"> Wide</b-form-select-option>-->
          <!--            <b-form-select-option :value="'small'">Small</b-form-select-option>-->
          <!--          </b-form-select>-->
          <span class="d-inline-block mx-3 align-top">Speaker: </span>
          <b-form-select
            class="mb-3"
            v-model="showAgent"
            size="sm"
            style="max-width: 180px"
          >
            <b-form-select-option :value="false">Bot</b-form-select-option>
            <b-form-select-option :value="true">Agent</b-form-select-option>
          </b-form-select>
          <div style="margin: auto">
            <chat-client
              :p-avatar="botAvatar.fileUrl || botAvatar.originalFileUrl"
              :p-agent-avatar="
                agentAvatar.fileUrl || agentAvatar.originalFileUrl
              "
              :p-banner="botBanner.fileUrl || botBanner.originalFileUrl"
              :p-banner-portrait-mode="
                botBannerPortraitMode.fileUrl ||
                botBannerPortraitMode.originalFileUrl
              "
              :p-bg-image="botBgImage.fileUrl || botBgImage.originalFileUrl"
              :p-bg-image-opacity="formItem.botBgImageOpacity / 100"
              :p-bg-color="formItem.botBgColor"
              :p-header-color="formItem.botColor"
              :p-close-button-color="formItem.botCloseButtonColor"
              :p-chat-bg-color="formItem.botChatBgColor"
              :p-chat-box-bg-color="formItem.botChatBoxBgColor"
              :p-text-color="formItem.botTextColor"
              :p-button-color="formItem.botButtonColor"
              :p-bot-close-button-text="formItem.botCloseButtonText"
              :p-member-chat-box-color="formItem.memberChatBoxColor"
              :p-member-text-color="formItem.memberTextColor"
              :p-bot-font-size="formItem.botFontSize"
              :p-intent-font-size="formItem.intentFontSize"
              :p-bot-banner-redirect-url="formItem.botBannerRedirectUrl"
              :p-bot-bg-image-format="formItem.botBgImageFormat"
              :p-announcement-is-enabled="formItem.announcementIsEnabled"
              :p-announcement-text="formItem.announcementText"
              :p-announcement-background-color="
                formItem.announcementBackgroundColor
              "
              :p-announcement-text-color="formItem.announcementTextColor"
              :p-screen-size="previewSize"
              :p-show-agent="showAgent"
              :p-bot-name="selectedBotName"
            />
          </div>
        </b-collapse>
      </b-col>
    </b-row>

    <hr />
    <b-form ref="main-form" @submit.prevent.stop="submit">
      <b-row class="mt-3">
        <b-col cols="12" xl="6">
          <b-card header="Header" class="mt-3">
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Header Background Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.botColor" />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Close Button Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.botCloseButtonColor" />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Close Button Text</b-col>
              <b-col cols="12" lg="6">
                <b-form-input
                  placeholder="x"
                  v-model="formItem.botCloseButtonText"
                  maxlength="10"
                  debounce="1000"
                  size="sm"
                />
              </b-col>
            </b-row>
            <b-row>
              <!--              <b-col cols="12">-->
              <!--                <b-form-checkbox-->
              <!--                  v-model="formItem.isDisplayBanner"-->
              <!--                  :value="true"-->
              <!--                  :unchecked-value="false"-->
              <!--                  >Display banner image?-->
              <!--                </b-form-checkbox>-->
              <!--              </b-col>-->
              <b-col class="mt-3" cols="12">
                <span class="ml-2">Banner in Landscape mode</span>
                <small class="text-muted"
                  >(recommended size: 800 x 60 px)</small
                >
              </b-col>
              <b-col cols="12">
                <image-handler
                  class="ml-3"
                  :customPreviewSettings="{
                    rounded: true,
                    width: 280,
                    height: 28,
                  }"
                  :image.sync="botBanner"
                  :maxDimensions="{ width: 1280, height: 120 }"
                />
              </b-col>
              <b-col cols="12 mt-3">
                <span class="ml-2">Banner in Portrait mode</span>
                <small class="text-muted"
                  >(recommended size: 480 x 80 px)</small
                >
              </b-col>
              <b-col cols="12">
                <image-handler
                  class="ml-3"
                  :customPreviewSettings="{
                    rounded: true,
                    width: 168,
                    height: 28,
                  }"
                  :image.sync="botBannerPortraitMode"
                  :maxDimensions="{ width: 1280, height: 120 }"
                />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Banner Redirect URL</b-col>
              <b-col cols="12" lg="6">
                <b-form-input
                  type="url"
                  placeholder="https://..."
                  v-model="formItem.botBannerRedirectUrl"
                  debounce="1000"
                  size="sm"
                />
              </b-col>
            </b-row>
          </b-card>
          <b-card header="Background" class="mt-3">
            <b-row class="mb-3">
              <b-col cols="12">
                <span class="ml-2">Background Image</span>
              </b-col>
              <b-col cols="12">
                <image-handler
                  class="ml-3"
                  :customPreviewSettings="{
                    rounded: true,
                    width: 80,
                    height: 80,
                  }"
                  :image.sync="botBgImage"
                  :maxDimensions="{ width: 600, height: 600 }"
                />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Background Image Opacity</b-col>
              <b-col cols="12" lg="6">
                <b-form-input
                  type="number"
                  v-model="formItem.botBgImageOpacity"
                  min="1"
                  max="100"
                  debounce="1000"
                  size="sm"
                  required
                />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Background Image Format</b-col>
              <b-col cols="12" lg="6">
                <b-form-select v-model="formItem.botBgImageFormat" size="sm">
                  <b-form-select-option value="0">Repeat</b-form-select-option>
                  <b-form-select-option value="1">Cover</b-form-select-option>
                  <b-form-select-option value="2">Contain</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Background Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.botBgColor" />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" xl="6">
          <b-card header="Body" class="mt-3">
            <!--            <b-row>-->
            <!--              <b-col cols="12">-->
            <!--                <b-form-checkbox-->
            <!--                  v-model="formItem.isDisplayMessageWithAvatar"-->
            <!--                  :value="true"-->
            <!--                  :unchecked-value="false"-->
            <!--                  >Display message with avatar?-->
            <!--                </b-form-checkbox>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <b-row>
              <b-col class="mt-3" cols="12">
                <span class="ml-2">Agent Avatar</span>
              </b-col>
              <b-col cols="12">
                <image-handler
                  class="ml-3"
                  :customPreviewSettings="{
                    rounded: 'circle',
                    width: 60,
                    height: 60,
                  }"
                  :image.sync="agentAvatar"
                  :maxDimensions="{ width: 600, height: 600 }"
                />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="mt-3" cols="12">
                <span class="ml-2">Bot Avatar</span>
              </b-col>
              <b-col cols="12">
                <image-handler
                  class="ml-3"
                  :customPreviewSettings="{
                    rounded: 'circle',
                    width: 60,
                    height: 60,
                  }"
                  :image.sync="botAvatar"
                  :maxDimensions="{ width: 600, height: 600 }"
                />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Chat Background Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.botChatBgColor" />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6"> Bot Chat Box Background Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.botChatBoxBgColor" />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Bot Text Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.botTextColor" />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Member Chat Box Background Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.memberChatBoxColor" />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Member Text Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.memberTextColor" />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Chat Text Size</b-col>
              <b-col cols="12" lg="6">
                <b-form-select v-model="formItem.botFontSize" size="sm">
                  <b-form-select-option value="0">Small</b-form-select-option>
                  <b-form-select-option value="1">Medium</b-form-select-option>
                  <b-form-select-option value="2">Large</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" lg="6">Intent Text Size</b-col>
              <b-col cols="12" lg="6">
                <b-form-select v-model="formItem.intentFontSize" size="sm">
                  <b-form-select-option value="0">Small</b-form-select-option>
                  <b-form-select-option value="1">Medium</b-form-select-option>
                  <b-form-select-option value="2">Large</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="6">Button Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler :colorHex.sync="formItem.botButtonColor" />
              </b-col>
            </b-row>
          </b-card>
          <b-card header="Announcement" class="mt-3">
            <b-row>
              <b-col>
                <b-form-checkbox
                  v-model="formItem.announcementIsEnabled"
                  :value="true"
                  :unchecked-value="false"
                  >Enable Announcement?
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12" lg="6">Annoucement Contents</b-col>
              <b-col cols="12" lg="6">
                <b-form-input
                  v-model="formItem.announcementText"
                  debounce="1000"
                  size="sm"
                />
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12" lg="6">Background Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler
                  :colorHex.sync="formItem.announcementBackgroundColor"
                />
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12" lg="6">Text Color</b-col>
              <b-col cols="12" lg="6">
                <color-handler
                  :colorHex.sync="formItem.announcementTextColor"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
    <b-row class="mt-3">
      <b-col>
        <b-button
          class="float-right"
          variant="primary"
          size="sm"
          @click="submit"
          >Submit
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import querystring from 'querystring';
import _ from 'lodash';
import ColorHandler from '@/components/ColorHandler';
import ImageHandler from '@/components/ImageHandler';
import BotSelector from '@/components/UI/BotSelector';
import httpHandler from '@/mixins/HttpHandler';
import ChatClient from '@/components/Client/ChatClient';
import ClientUiConfigActivityLog from '@/components/Bots/ClientUiConfigActivityLog';

export default {
  name: 'ClientSettings',
  mixins: [httpHandler],
  components: {
    ColorHandler,
    ImageHandler,
    BotSelector,
    ChatClient,
    ClientUiConfigActivityLog,
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    if (botId) this.changeBot(botId);
  },
  data() {
    return {
      botId: null,
      previewSize: 'wide',
      showAgent: false,
      formItem: {
        botId: null,
        isDisplayBanner: true,
        isDisplayMessageWithAvatar: true,
        botColor: '',
        botBanner: '',
        botBannerPotraitMode: '',
        agentAvatar: '',
        botAvatar: '',
        botBgImage: '',
        botBgImageOpacity: '50',
        botBgColor: '#EFF2FB',
        botChatBgColor: '#DAF3FB',
        botChatBoxBgColor: '#565661',
        botButtonColor: '#43C1EF',
        botCloseButtonColor: '#EE4444',
        botTextColor: '#FFFFFF',

        botCloseButtonText: '',
        memberChatBoxColor: '',
        memberTextColor: '',
        botFontSize: 0,
        intentFontSize: 0,
        botBannerRedirectUrl: '',
        botBgImageFormat: 0,
        announcementIsEnabled: false,
        announcementText: '',
        announcementBackgroundColor: '',
        announcementTextColor: '',
      },
      botBanner: {
        fileName: null,
        fileBytes: null,
        fileUrl: null,
        originalFileUrl: null,
      },
      botBannerPortraitMode: {
        fileName: null,
        fileBytes: null,
        fileUrl: null,
        originalFileUrl: null,
      },
      agentAvatar: {
        fileName: null,
        fileBytes: null,
        fileUrl: null,
        originalFileUrl: null,
      },
      botAvatar: {
        fileName: null,
        fileBytes: null,
        fileUrl: null,
        originalFileUrl: null,
      },
      botBgImage: {
        fileName: null,
        fileBytes: null,
        fileUrl: null,
        originalFileUrl: null,
      },
    };
  },
  computed: {
    previewSizeStyle() {
      const selectedPreviewSize = this.previewSize;
      if (selectedPreviewSize === 'small') return { width: '450px' };
      else return { 'min-width': '880px' };
    },
    selectedBotName() {
      return this.$store.state.selectedBotName;
    },
  },
  methods: {
    changeBot(botId) {
      if (botId) {
        const mainForm = this.$refs['main-form'];
        mainForm.reset();

        this.botId = botId;
        this.httpclient
          .get('/api/botClientConfigs/' + botId)
          .then((resp) => {
            this.formItem = {};
            return resp;
          })
          .then((resp) => {
            //no need to load if it is not the same selected bot
            if (this.botId !== botId) {
              return;
            }

            if (resp.data) {
              const formItem = _.cloneDeep(resp.data);

              this.botBanner = _.cloneDeep({
                fileName: formItem.bannerFileName,
                fileBytes: formItem.bannerFileBytes,
                fileUrl: null,
                originalFileUrl: formItem.botBanner,
              });

              this.botBannerPortraitMode = _.cloneDeep({
                fileName: formItem.bannerPortraitModeFileName,
                fileBytes: formItem.bannerPortraitModeFileBytes,
                fileUrl: null,
                originalFileUrl: formItem.botBannerPotraitMode,
              });

              this.agentAvatar = _.cloneDeep({
                fileName: formItem.agentAvatarFileName,
                fileBytes: formItem.agentAvatarFileBytes,
                fileUrl: null,
                originalFileUrl: formItem.agentAvatar,
              });

              this.botAvatar = _.cloneDeep({
                fileName: formItem.avatarFileName,
                fileBytes: formItem.avatarFileBytes,
                fileUrl: null,
                originalFileUrl: formItem.botAvatar,
              });

              this.botBgImage = _.cloneDeep({
                fileName: formItem.backgroundImageFileName,
                fileBytes: formItem.backgroundImageFileBytes,
                fileUrl: null,
                originalFileUrl: formItem.botBgImage,
              });

              //load default colors
              formItem.botBgImageOpacity = formItem.botBgImageOpacity || 50;
              formItem.botBgColor = formItem.botBgColor || '#EFF2FB';
              formItem.botChatBgColor = formItem.botChatBgColor || '#DAF3FB';
              formItem.botChatBoxBgColor =
                formItem.botChatBoxBgColor || '#565661';
              formItem.botButtonColor = formItem.botButtonColor || '#43C1EF';
              formItem.botCloseButtonColor =
                formItem.botCloseButtonColor || '#EE4444';
              formItem.botTextColor = formItem.botTextColor || '#FFFFFF';
              this.formItem = formItem;
            } else {
              this.formItem = _defaultFormItem();
            }
            this.formItem.botId = botId;
          });
      }
    },
    submit() {
      const mainForm = this.$refs['main-form'];

      if (!mainForm.reportValidity()) {
        return;
      }

      const confirmMessage = 'Update the client settings?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then((confirm) => {
          if (confirm) {
            const formItem = _.cloneDeep(this.formItem);

            formItem.bannerFileName = this.botBanner.fileName;
            formItem.bannerFileBytes = this.botBanner.fileBytes;
            formItem.botBanner = this.botBanner.originalFileUrl;

            formItem.bannerPortraitModeFileName =
              this.botBannerPortraitMode.fileName;
            formItem.bannerPortraitModeFileBytes =
              this.botBannerPortraitMode.fileBytes;
            formItem.botBannerPotraitMode =
              this.botBannerPortraitMode.originalFileUrl;

            formItem.agentAvatarFileName = this.agentAvatar.fileName;
            formItem.agentAvatarFileBytes = this.agentAvatar.fileBytes;
            formItem.agentAvatar = this.agentAvatar.originalFileUrl;

            formItem.avatarFileName = this.botAvatar.fileName;
            formItem.avatarFileBytes = this.botAvatar.fileBytes;
            formItem.botAvatar = this.botAvatar.originalFileUrl;

            formItem.backgroundImageFileName = this.botBgImage.fileName;
            formItem.backgroundImageFileBytes = this.botBgImage.fileBytes;
            formItem.botBgImage = this.botBgImage.originalFileUrl;

            this.httpclient
              .post('/api/botClientConfigs/', formItem)
              .then(() => {
                this.$bvModal
                  .msgBoxOk(
                    'Bot client settings had been updated successfully',
                    {
                      okVariant: 'success',
                    }
                  )
                  .then(() => {
                    this.changeBot();
                  });
              });
          }
        });
    },
  },
};

function _defaultFormItem() {
  return {
    isDisplayBanner: true,
    isDisplayMessageWithAvatar: true,
    botColor: '#EFF2FB',
    botBanner: '',
    botBannerPotraitMode: '',
    agentAvatar: '',
    botAvatar: '',
    botBgImage: '',
    botBgImageOpacity: 50,
    botBgColor: '#EFF2FB',
    botChatBgColor: '#DAF3FB',
    botChatBoxBgColor: '#565661',
    botButtonColor: '#43C1EF',
    botCloseButtonColor: '#EE4444',
    botTextColor: '#FFFFFF',

    memberChatBoxColor: '#565661',
    memberTextColor: '#FFFFFF',

    botCloseButtonText: null,
    botFontSize: 0,
    intentFontSize: 0,
    botBannerRedirectUrl: null,
    botBgImageFormat: 0,

    announcementIsEnabled: false,
    announcementText: '',
    announcementBackgroundColor: '#F5F5F5',
    announcementTextColor: '#f26b6b',
  };
}
</script>

<style scoped>
.preview-body {
  overflow-x: scroll;
}

.client-frame {
  border: none;
  width: 100%;
  /*max-width: 1000px;*/
  height: calc(100vh);
}
</style>
