<template>
  <b-row class="mb-4">
    <!-- Non-collapsible fields -->
    <b-col
      v-for="(config, index) in visibleFields.filter(field => !fieldsInCollapse.includes(field.field))"
      :key="index"
      cols="12"
    >
      <div class="form-group d-flex align-items-center">
        <!-- Label -->
        <label class="form-label mr-2 mb-0" style="min-width: 150px;">{{ config.label }}</label>
        <!-- Render input for standard fields -->
        <b-input
          v-if="!config.component && config.field !== 'urlPath'"
          v-model.trim="botMarioConfig[config.field]"
          :type="config.type || 'text'"
          class="form-control flex-grow-1"
          :placeholder="config.placeholder || ''"
          size="sm"
          :name="config.field"
          :readonly="config.readonly"
        />
        <!-- Render urlPath as clickable link -->
        <a
          v-if="config.field === 'urlPath' && isValidUrl(botMarioConfig[config.field])"
          :href="botMarioConfig[config.field]"
          target="_blank"
          style="text-decoration: none; color: #007bff;"
        >
          {{ botMarioConfig[config.field] }}
        </a>
        <span
          v-else-if="config.field === 'urlPath'"
        >
          {{ botMarioConfig[config.field] }}
        </span>
        <!-- Render custom component if defined -->
        <component
          v-else
          :is="config.component"
          v-model="botMarioConfig[config.field]"
          v-bind="config.props"
          :class="['custom-component flex-grow-1', { 'is-invalid': validationStates[config.field] === false }]"
          :name="config.field"
        />
        <span v-if="validationStates[config.field] === false" class="error-text ml-2">
          Please fill up this field
        </span>
      </div>
    </b-col>

    <!-- Extra URL Input Section (under Url Path) -->
    <b-col cols="12" v-if="visibleFields.some(field => field.field === 'urlPath')">
      <div class="form-group d-flex align-items-center">
        <label class="form-label mr-2 mb-0" style="width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Custom Domain</label>
        <div class="p-3 flex-grow-1">
          <div class="intents__input-questions">
            <div
              v-for="(botUrl, index) in botMarioUrl"
              :key="index"
              class="intents__input-question-row row align-items-center"
            >
              <div class="col-sm-11">
                <b-input
                  type="text"
                  class="form-control form-control-sm intents__input-question"
                  v-model.trim="botUrl.botUrls"
                  :id="'intents__input-question-' + index"
                  @blur="trimUrl(index)"
                />
              </div>
              <div class="col-sm-1 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-outline-danger btn-sm p-0 d-flex align-items-center justify-content-center"
                  @click="removeQuestion(index)"
                  aria-label="Remove domain"
                  style="width: 30px; height: 30px; border: 1px solid #ccc; border-radius: 4px; background-color: #f8f9fa;"
                >
                  &#8722;
                </button>
              </div>
            </div>
          </div>
          <span class="intents__btn-add clickable intents__add-question" @click="addQuestion">
            <i class="fas fa-plus mr-2"></i>Add domain
          </span>
        </div>
      </div>
    </b-col>

    <!-- Collapsible section -->
    <b-col cols="12">
      <section>
        <span class="btn-advance-settings" @click="toggleAdvanceSettings = !toggleAdvanceSettings">
          <b-icon-caret-right-fill /> Advance Settings
        </span>
        <b-collapse v-model="toggleAdvanceSettings">
          <b-row class="my-2">
            <b-col
              v-for="(config, index) in visibleFields.filter(field => fieldsInCollapse.includes(field.field))"
              :key="index"
              cols="12"
            >
              <div class="form-group d-flex align-items-center">
                <!-- Label -->
                <label class="form-label mr-2 mb-0" style="min-width: 150px;">{{ config.label }}</label>
                <!-- Render input for standard fields -->
                <b-input
                  v-if="!config.component"
                  v-model.trim="botMarioConfig[config.field]"
                  :type="config.type || 'text'"
                  class="form-control flex-grow-1"
                  :placeholder="config.placeholder || ''"
                  size="sm"
                  :name="config.field"
                  :readonly="config.readonly"
                />
                <!-- Render custom component if defined -->
                <component
                  v-else
                  :is="config.component"
                  v-model="botMarioConfig[config.field]"
                  v-bind="config.props"
                  :class="['custom-component flex-grow-1', { 'is-invalid': validationStates[config.field] === false }]"
                  :name="config.field"
                />
                <span v-if="validationStates[config.field] === false" class="error-text ml-2">
                  Please fill up this field
                </span>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </section>
    </b-col>
  </b-row>
</template>


<style scoped>
    .error-text {
      color: red;
      font-size: 16px;
      margin-top: 5px;
      display: block;
    }

    .custom-component.is-invalid {
      border-color: red; /* Optional: Change border color for invalid fields */
    }

    .intents__input-question-row {
      margin-bottom: 10px;
    }

    .intents__btn-add {
      display: inline-block;
      cursor: pointer;
      color: #007bff;
      font-size: 14px;
    }

    .intents__btn-add:hover {
      text-decoration: underline;
    }

    .btn-link {
        display: block !important;
        visibility: visible !important;
    }

    button {
        box-sizing: border-box;
    }

    .intents__input-questions {
        flex-grow: 1;
        width: auto; /* Let the width adjust automatically */
        max-width: 100%; /* Prevent overflow */
    }


</style>


<script>
  import CustomValues from '@/components/Intents/CustomValues';
  import VJsonEditor from '@/components/UI/JsonEditor';
  import DefaultValues from '@/components/Intents/DefaultValues';

  export default {
    components: {
      CustomValues,
      VJsonEditor,
      DefaultValues,
    },
    props: {
      botMarioConfig: { type: Object, required: true },
      botMarioUrl: { type: Array, required: true },
    },
    computed: {
      visibleFields() {
        return this.advancedFields.filter((config) => !config.hidden);
      },
    },
    data() {
      return {
        advancedFields: [
          // Fields from the first template
          { label: "Domain", field: "urlPath", placeholder: "Auto Generate Upon Creating", type: "url", readonly: true, hidden: false },
          { label: "Db Profile", field: "dbProfile", placeholder: "mario.staging.1", required: false, hidden: true },
          { label: "Client", field: "client", placeholder: "GUID Path", required: false, hidden: true },
          { label: "Title", field: "title", placeholder: "This is the title for the Bot page.", required: false, hidden: false },
          { label: "Api Paths", field: "apiPaths", type: "url", placeholder: "https://staging-app.bimariobot.com/nodeApi", required: false, hidden: true },
          { label: "Plugin Paths", field: "pluginPaths", type: "url", placeholder: "https://staging-app.bimariobot.com/plugin", required: true, hidden: true },
          { label: "Socket Paths", field: "socketPaths", placeholder: "wss://staging-bot.bimariobot.com/socket", required: false, hidden: true },
          { label: "Access Token", field: "accessToken", placeholder: "aWdTYlR7ImNpcGhlcl90ZXh0IjogIkxPalB2RHZaSzIrZFEx==", required: true, hidden: true },
          { label: "Brand", field: "brandId", placeholder: "Account name used for the bot.", required: false, hidden: false },
          // Fields from the second template
          { label: "Salesforce Url", field: "salesforceUrl", type: "url", placeholder: "https://123abc.salesforceliveagent.com", required: false, hidden: true },
          { label: "GBQ Api Url", field: "gbqApiUrl", type: "url", placeholder: "https://api123-staging.wzryjc.cc", required: false, hidden: true },
          { label: "GBQ Api Authentication", field: "gbqApiAuthentication", placeholder: "abc1234==", required: false, hidden: true },
          { label: "X-Api Key", field: "xApiKey", placeholder: "vuh123343ewds", required: false, hidden: true },
          { label: "Organisation Id", field: "organisationId", placeholder: "123abc", required: false, hidden: true },
          { label: "Deployment Id", field: "deploymentId", placeholder: "123abc", required: false, hidden: true },
          { label: "Version", field: "version", placeholder: "49", required: false, hidden: true },
          { label: "Affinity", field: "affinity", placeholder: "null", required: false, hidden: true },
          { label: "Agent Reconnect Buffer", field: "agentReconnectBuffer", placeholder: "1 * 60", required: false, hidden: true },
          { label: "Kzing Brand", field: "kzingBrand", placeholder: "1,2,3,4", required: false, hidden: true },
          {
            label: "Livechat Values",
            field: "defaultValues",
            component: "DefaultValues",
            props: { required: false },
            hidden: false,
          },
          { label: "Brand Market", field: "brandMarket", placeholder: "Check VIP params", required: false, hidden: false },
          { label: "Brand Name", field: "brandName", placeholder: "Check VIP params", required: false, hidden: false },
          { label: "Customer", field: "customer", placeholder: "Login Params", required: false, hidden: false },
          { label: "Mobile Api Type", field: "mobileApiType", placeholder: "Fun88", required: false, hidden: true },
          {
           label: "Message",
           field: "message",
           component: "DefaultValues",
           props: { required: false },
           hidden: false,
          },
          {
            label: "Mobile Api Detail",
            field: "mobileApiDetail",
            component: "VJsonEditor",
            props: {
              options: { mode: "code", mainMenuBar: false },
            },
            hidden: false,
          },
          {
            label: "Public Key",
            field: "publicKey",
            required: false,
            hidden: false,
            component: "b-textarea",
            props: {
              rows: 10,
            },
          },
        ],
        validationStates: {},
        toggleAdvanceSettings: false,
        fieldsInCollapse: [
          "title",
          "brandId",
          "accessToken",
          "defaultValues",
          "brandMarket",
          "brandName",
          "customer",
          "message",
          "mobileApiDetail",
          "publicKey"
        ],
      };
    },
    methods: {
      trimUrl(index) {
        const botUrlsArray = this.botMarioUrl.map(item => item.botUrls);
        if (botUrlsArray[index]) {
          this.botMarioUrl[index].botUrls = botUrlsArray[index].trim(); 
        }
      },
      addQuestion() {
        this.botMarioUrl.push({
          botUrls: '',
          botId: '',    
        });
      },
      removeQuestion(index) {
        this.botMarioUrl.splice(index, 1);
      },
      isValidUrl(url) {
          try {
            new URL(url);
            return true;
          } catch (e) {
            return false;
          }
        },
      validateForm() {
        let isValid = true;

        this.advancedFields.forEach((config) => {
          const value = this.botMarioConfig[config.field];
          // Custom validation for CustomValues component
          if (config.component === "CustomValues" && Array.isArray(value)) {
            if (value.length === 0) {
              isValid = false;
              this.$set(this.validationStates, config.field, false);
            }
            else {
              const invalidCustomValue = value.some(
                (item) => !item.key || !item.value
              );
              if (invalidCustomValue) {
                isValid = false;
                this.$set(this.validationStates, config.field, false);
              } else {
                this.$set(this.validationStates, config.field, true);
              }
            }
          }
          // Custom validation for VJsonEditor component
          /*else if (config.component === "VJsonEditor") {
            try {
              if (!value || Object.keys(value).length === 0) {
                isValid = false;
                this.$set(this.validationStates, config.field, false);
              } else {
                JSON.stringify(value); // Ensure JSON is valid
                this.$set(this.validationStates, config.field, true);
              }
            } catch (e) {
              isValid = false;
              this.$set(this.validationStates, config.field, false);
            }
          } */
          else {
            this.$set(this.validationStates, config.field, true);
          }
        });

        return isValid;
      },
    },
  };
</script>
