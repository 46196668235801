<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <div class="d-inline-block mr-3">
          <span class="page-header">Audits</span>
        </div>
      </b-col>
    </b-row>
    <b-form>
      <b-row class="my-2">
        <b-col cols="12" lg="2">
          <label>Date Range</label>
        </b-col>
        <b-col cols="12" lg="4">
          <date-range-picker
            ref="picker"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
            :minDate="minDate"
            :maxDate="maxDate"
            :timePicker="true"
            :ranges="defaultRanges()"
            v-model="dateRange"
          >
            <div slot="input" slot-scope="picker" class="form-inline">
              <small class="text-muted mr-1">from</small>
              <span class="form-control form-control-sm">{{
                picker.startDate | datetime
              }}</span>
              <small class="text-muted mx-1">to</small>
              <span class="form-control form-control-sm">{{
                picker.endDate | datetime
              }}</span>
            </div>
          </date-range-picker>
        </b-col>
        <b-col cols="12" lg="2">
          <label>Module</label>
        </b-col>
        <b-col cols="12" lg="4">
          <b-input
            placeholder="e.g. /bots"
            class="form-control-sm"
            v-model.trim="queryParams.URLAccessed"
          ></b-input>
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col cols="12" lg="2">
          <label>Session ID</label>
        </b-col>
        <b-col cols="12" lg="4">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.sessionId"
          ></b-input>
        </b-col>
        <b-col cols="12" lg="2">
          <label>Username</label>
        </b-col>
        <b-col cols="12" lg="4">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.userName"
          ></b-input>
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col cols="12">
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="getList"
          >
            <b-icon-search />
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div ref="table-container" v-if="rows > 0">
      <small class="text-muted"
        >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
      <b-table
        bordered
        small
        :responsive="true"
        striped
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
        sort-by="5"
        class="chat-histories-table"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(7)="data">
          <small class="clickable" @click="data.toggleDetails">
            {{ data.detailsShowing ? 'Hide' : 'Show' }}
          </small>
        </template>
        <template v-slot:row-details="data">
          <json-viewer :value="JSON.parse(data.item[7])" />

          <!--          <code lang="json">{{-->
          <!--            JSON.stringify(JSON.parse(data.item[7]), null, 2)-->
          <!--          }}</code>-->
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import dayjs from 'dayjs';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import httpclient from '@/mixins/HttpHandler';
import JsonViewer from '@/components/UI/JsonViewer';

export default {
  mixins: [httpclient],
  components: { DateRangePicker, JsonViewer },
  mounted() {
    let datepicker = document.getElementsByClassName('reportrange-text');
    datepicker[0].classList.add('form-control-sm');

    this.dateRange = {
      startDate: dayjs().startOf('day').toDate(),
      endDate: dayjs().add(1, 'days').startOf('day').toDate(),
    };

    this.getList();
  },
  data() {
    return {
      queryParams: {
        sessionId: '',
        userName: '',
        URLAccessed: '',
        fromDate: '',
        toDate: '',
      },
      dateRange: {},
      fields: [
        {
          key: '0',
          label: 'Session ID',
          sortable: false,
        },
        {
          key: '2',
          label: 'IP Address',
          sortable: true,
        },
        {
          key: '3',
          label: 'Username',
          sortable: true,
        },
        {
          key: '4',
          label: 'Module',
          sortable: true,
        },
        {
          key: '5',
          label: 'Datetime',
          sortable: true,
        },
        {
          key: '7',
          label: 'Data',
          sortable: true,
        },
      ],
      items: [],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    minDate() {
      return dayjs().add(-2, 'months').set('date', 1).startOf('day').toDate();
    },
    maxDate() {
      return dayjs().add(1, 'days').toDate();
    },
    rows() {
      return this.items.length;
    },
  },
  methods: {
    defaultRanges() {
      const today = dayjs().startOf('day').toDate();
      const tomorrow = dayjs().add(1, 'days').startOf('day').toDate();
      const yesterday = dayjs().add(-1, 'days').startOf('day').toDate();
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonday = dayjs().startOf('isoWeek');
      const prevMonday = dayjs().add(-7, 'days').startOf('isoWeek');

      const previousHour = dayjs().add(-1, 'hours').startOf('hour').toDate();
      const thisHour = dayjs().startOf('hour').toDate();
      const nextHour = dayjs().add(1, 'hours').startOf('hour').toDate();

      return {
        'Previous Hour': [previousHour, thisHour],
        'This Hour': [thisHour, nextHour],
        Today: [today, tomorrow],
        Yesterday: [yesterday, today],
        'Previous Week': [prevMonday, thisMonday],
        'This Week': [thisMonday, tomorrow],
        'This Month': [thisMonthStart, tomorrow],
      };
    },
    getList() {
      const vm = this;
      vm.queryParams.fromDate = dayjs(this.dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      vm.queryParams.toDate = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      vm.httpclient
        .get('api/botaudits', {
          params: vm.queryParams,
        })
        .then(function (resp) {
          if (resp.data) {
            vm.items = resp.data;
            vm.currentPage = 1;
          } else {
            vm.items = [];
          }
        })
        .catch(function () {
          vm.items = [];
        });
    },
  },
};
</script>