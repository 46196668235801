import axios from 'axios';
const instance = axios.create({
    baseURL: window.location.origin
});

function getList() {
    return instance.get('api/AdminBots');
}

function switchBotType(bot) {
    return instance.put(`api/AdminBots/${bot.botId}`, bot);
}

function setSecurityKey(botId, securityKey) {
    return instance.put(`api/AdminBots/${botId}/securityKey`, securityKey, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export default {
    getList,
    switchBotType,
    setSecurityKey
};