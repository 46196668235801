<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <h1>Admin Bot Management</h1>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <b-list-group>
          <b-list-group-item v-for="bot in bots" :key="bot.botId">
            <b-badge class="mr-2">{{ bot.botId }}</b-badge>
            <span>{{ bot.botName }}</span>
            <b-form-select class="d-inline-block bot-type-select" size="sm" :options="botTypes" v-model="bot.botType"
                           @change="(e) => {changeType(e, bot)}"/>
            <b-button class="d-inline-block bot-type-select mr-2" size="sm" variant="outline-primary"
                      @click="openSecurityKeyModal(bot.botId)">
              Set Security Key
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <b-modal v-model="securityKeyModal" centered button-size="sm" hide-header @ok.prevent="setSecurityKey">
      <b-form ref="securityKeyForm" @submit.prevent.stop="setSecurityKey">
        <b-textarea v-model="securityKey" required />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import adminBotsServices from "../../services/Admin/AdminBotsServices";

export default {
  name: "AdminBotManagement",
  data() {
    return {
      securityKeyModal: false,
      selectedBotId: null,
      securityKey: "",
      
      bots: [],
      botTypes: [
        {value: 0, text: 'Old Bot'},
        {value: 1, text: 'Mario'},
        {value: 2, text: 'Skype Bot'},
      ],
    }
  },
  mounted() {
    this.getBots();
  },
  methods: {
    async getBots() {
      const resp = await adminBotsServices.getList();
      let bots = resp.data;
      bots = _.map(bots, (b) => {
        return {...b, ...{originalType: b.botType}}
      });
      bots = _.orderBy(bots, ['botId']);
      this.bots = bots;
    },
    async changeType(type, bot) {
      const confirm = await this.$bvModal.msgBoxConfirm('Proceed to change the bot type?', {
        centered: true,
        buttonSize: 'sm',
        noCloseOnBackdrop: true,
        noCloseOnEsc: true
      });
      if (confirm) {
        try {
          const resp = await adminBotsServices.switchBotType(bot);
          if (resp.data.botType === type) {
            await this.$bvModal.msgBoxOk('Bot type had been edited successfully.', {
              okVariant: 'success',
              centered: true,
              buttonSize: 'sm',
            });
          } else {
            await this.$bvModal.msgBoxOk('Failed. Please try again later.', {
              okVariant: 'danger',
              centered: true,
              buttonSize: 'sm',
            });
            this.revertChange(bot);
          }

        } catch {
          this.revertChange(bot);
        }
      } else {
        this.revertChange(bot);
      }
    },
    revertChange(bot) {
      bot.botType = bot.originalType;
    },
    openSecurityKeyModal(botId) {
      this.securityKeyModal = true;
      this.securityKey = '';
      this.selectedBotId = botId;
    },
    async setSecurityKey() {
      const securityKeyForm = this.$refs.securityKeyForm;

      if (!securityKeyForm.reportValidity()) {
        return;
      }
      
      const confirm = await this.$bvModal.msgBoxConfirm('Proceed to set security key?', {
        centered: true,
        buttonSize: 'sm',
        noCloseOnBackdrop: true,
        noCloseOnEsc: true
      });
      if (confirm) {
        try {
          const resp = await adminBotsServices.setSecurityKey(this.selectedBotId, this.securityKey);
          if (resp.data) {
            await this.$bvModal.msgBoxOk('Security key had been set successfully.', {
              okVariant: 'success',
              centered: true,
              buttonSize: 'sm',
            });
            this.securityKeyModal = false;
          } else {
            await this.$bvModal.msgBoxOk('Failed. Please try again later.', {
              okVariant: 'danger',
              centered: true,
              buttonSize: 'sm',
            });
          }

        } catch {
          //do nothing
        }
      }
    }
  }
}
</script>

<style scoped>
.bot-type-select {
  float: right;
  max-width: 200px;
}
</style>